import { createSlice } from '@reduxjs/toolkit';
import operation from './auth-operation';
import operationUser from '../user/user-operation';

const initialState = {
  userName: null,
  userEmail: null,
  userId: null,
  token: '',
  isLoggedGoogleIn: false,
  isRefreshing: true,
  role: '',
  subscription: '',
  loading: { loading: false, loadingVerify: false },
  isVerify: true,
  isRegister: false,
  validationRememberToken: '',
  avatar: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onClearValidationRememberToken(state, { payload }) {
      state.validationRememberToken = '';
    },
  },
  extraReducers: {
    //=========Global Operation Start=========//
    //====changeUserInfo====//
    [operationUser.changeUserInfo.fulfilled]: (state, { payload }) => {
      state.userName = payload.name;
      state.avatar = payload.avatar;
      state.userEmail = payload.email;
    },
    //=========Global Operation End=========//
    //====signIn====//
    [operation.signIn.fulfilled]: (state, { payload }) => {
      state.userName = payload.userName;
      state.token = payload.token;
      state.role = payload.role;
      state.userId = payload.id;
      state.userName = payload.name;
      state.userEmail = payload.email;
      state.subscription = payload.subscription;
      state.avatar = payload.avatar;
    },
    //====signUp====//
    [operation.signUp.fulfilled]: (state, { payload }) => {
      state.isRegister = true;
    },
    //====isInfoClose====//
    [operation.isInfoClose.fulfilled]: (state, { payload }) => {
      state.isRegister = false;
    },
    //====googleIn====//
    [operation.googleIn.pending]: (state, _) => {
      state.isLoggedGoogleIn = true;
    },
    [operation.googleIn.fulfilled]: (state, { payload }) => {
      state.userEmail = payload.email;
      state.userName = payload.name;
      state.isLoggedGoogleIn = false;
    },
    [operation.googleIn.rejected]: (state, _) => {
      state.isLoggedGoogleIn = false;
    },
    //====refreshCurrentUser====//
    [operation.refreshCurrentUser.pending]: (state, action) => {
      state.isRefreshing = true;
    },
    [operation.refreshCurrentUser.fulfilled]: (state, { payload }) => {
      const { persistedToken, user } = payload;
      const { name, role, subscription, email, id, avatar } = user;

      state.token = persistedToken;
      state.userName = name;
      state.role = role;
      state.subscription = subscription;
      state.userEmail = email;
      state.isRefreshing = false;
      state.userId = id;
      state.avatar = avatar;
    },
    [operation.refreshCurrentUser.rejected]: (state, { payload }) => {
      state.token = null;
      state.isRefreshing = false;
    },
    //====clearToken====//
    [operation.logOut.fulfilled]: (state, { payload }) => {
      state.token = null;
      state.role = '';
    },

    //====verifyEmail====//
    [operation.verifyEmail.pending]: (state, action) => {
      state.loading.loadingVerify = true;
    },
    [operation.verifyEmail.fulfilled]: (state, { payload }) => {
      state.isVerify = true;
      state.loading.loadingVerify = false;
    },
    [operation.verifyEmail.rejected]: (state, { payload }) => {
      state.loading.loadingVerify = false;
      state.isVerify = false;
    },

    //====verifyRememberToken====//
    [operation.verifyRememberToken.pending]: (state, action) => {
      state.loading.loading = true;
    },
    [operation.verifyRememberToken.fulfilled]: (state, { payload }) => {
      state.validationRememberToken = payload.validation_remember_token;
      state.loading.loading = false;
    },
    [operation.verifyRememberToken.rejected]: (state, { payload }) => {
      state.loading.loading = false;
    },
  },
});

export const { onClearValidationRememberToken } = authSlice.actions;
export default authSlice.reducer;
