import { Card, Typography, CardContent } from '@mui/material';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

export const AccordionModulesCard = ({ data, open }) => {
  return (
    <Card sx={{ maxWidth: '100%', display: 'flex', cursor: 'pointer', }}>
      <CardContent sx={{ width: '100%', px: 2, py: 0, pt: 3, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography variant="h5">
          {data.name}
        </Typography>
        <ArrowBackIosRoundedIcon
          sx={{
            transform: open ? "rotate(-90deg)" : "rotate(0)",
            transition: "transform 0.3s ease-in-out"
          }}
        />
      </CardContent>
    </Card >
  );
};

