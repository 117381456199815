import {
  ModalBasic,
  Logic,
  MultipleSelectCheckmarks,
  TextArea,
  FileLoader
} from '@root/components';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import operations from '@root/redux/admin/admin-operation';
import 'react-quill/dist/quill.snow.css';
import { MultiList } from '@root/components/MultiList/MultiList';
import { serialize } from 'object-to-formdata';

export const FormCourse = ({ handleClose, initData, options }) => {
  const [data, setData] = useState({
    title: '',
    lvl: '',
    description: '',
    modules: [],
    count_video: '',
    course_duration: '',
    per_week: '',
    suitable_for_those_who: [],
    course_overview: []
  });
  const [about, setAbout] = useState('');
  const [talking, setTalking] = useState('');
  const [courseLogo, setCourseLogo] = useState(false);
  const [videoOverview, setVideoOverview] = useState(false);
  const [error, setError] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (initData) {
      const {
        name,
        description,
        lvl,
        modules,
        about,
        talking,
        count_video,
        course_duration,
        per_week,
        suitable_for_those_who = [],
        course_overview = [],
        course_logo,
        video_overview
      } = initData;
      setAbout(about)
      setTalking(talking)
      if (course_logo?.path) setCourseLogo(course_logo)
      if (video_overview?.path) setVideoOverview(video_overview)
      setData({
        title: name,
        description,
        lvl,
        modules,
        count_video,
        course_duration,
        per_week,
        suitable_for_those_who,
        course_overview
      });
    }
  }, [initData]);

  const handelSubmit = e => {
    e.preventDefault();
    if (!validation()) {
      const {
        title,
        lvl,
        description,
        modules,
        count_video,
        course_duration,
        per_week,
        suitable_for_those_who,
        course_overview
      } = data;

      if (initData) {
        let dataST = {
          name: title,
          description,
          lvl,
          modules,
          about,
          talking,
          count_video,
          course_duration,
          per_week,
          suitable_for_those_who,
          course_overview,
        };

        if (!courseLogo?._id) {
          dataST = { ...dataST, course_logo: courseLogo };
        }
        if (!videoOverview?._id) {
          dataST = { ...dataST, video_overview: videoOverview };
        }

        const sendDate = serialize(
          {
            ...dataST
          },
          {
            indices: true,
            nullsAsUndefineds: true,
            booleansAsIntegers: true,
            noFilesWithArrayNotation: true,
          },
        )
        dispatch(
          operations.updateCourse({ courseId: initData._id, sendDate }),
        ).then(() => {
          handleClose();
        })
      } else {
        const sendDate = serialize(
          {
            name: title,
            description,
            lvl,
            modules,
            about,
            talking,
            count_video,
            course_duration,
            per_week,
            suitable_for_those_who,
            course_overview,
            course_logo: courseLogo,
            video_overview: videoOverview
          },
          {
            indices: true,
            nullsAsUndefineds: true,
            booleansAsIntegers: true,
            noFilesWithArrayNotation: true,
          },
        );
        dispatch(
          operations.createCourse(sendDate),
        ).then(() => {
          handleClose();
        })
      }

    }
  };

  const handelDelete = e => {
    dispatch(operations.deleteCourse({ courseId: initData._id }));
    handleClose();
  };

  const validation = () => {
    const { title, lvl } = data;
    if (!title || title === '') {
      setError({ title: { init: true, text: 'Required' } });
      return true;
    }

    if (!lvl || lvl === '') {
      setError({ lvl: { init: true, text: 'Required' } });
      return true;
    }

    setError({});
    return false;
  };

  const onInputChange = e => {

    setData({ ...data, [e.target.name]: e.target.value });
  };

  const changeModules = arr => setData({ ...data, modules: [...arr] });

  const onChangeIterableList = (name) => (value, idx) => {
    const copy = [...data[name]]
    copy[idx] = value
    setData({ ...data, [name]: copy })

  }

  const onDeleteItemFromIterableList = (name) => (idx) => {
    const copy = [...data[name]]

    if (idx === 0) {
      copy.shift()
    } else {
      copy.splice(idx, idx)
    }

    setData({ ...data, [name]: copy })
  }

  const {
    title,
    lvl,
    description,
    modules,
    count_video,
    course_duration,
    per_week,
    suitable_for_those_who,
    course_overview
  } = data;

  return (
    <>
      <ModalBasic handleClose={handleClose}>
        <Form onSubmit={handelSubmit}>
          <Title>{initData ? 'Edit' : 'Create'} the Course</Title>
          <FormContext>
            <WrapperForm>
              <TextField
                error={error?.title?.init}
                helperText={error?.title?.text}
                id="outlined-basic"
                label="Title"
                variant="outlined"
                name="title"
                value={title}
                onInput={onInputChange}
                size="small"
                margin="dense"
                sx={{ width: '100%' }}
              />

              <TextArea
                value={about}
                onChange={setAbout}
                placeholder={'Enter an About'}
                label={'About'}
              />

              <TextField
                error={error?.lvl?.init}
                helperText={error?.lvl?.text}
                id="outlined-basic"
                label="lvl"
                variant="outlined"
                name="lvl"
                value={lvl}
                onInput={onInputChange}
                size="small"
                margin="dense"
                sx={{ width: '100%' }}
              />
              <FileLoader
                title={'Завантажити лого курсу'}
                file={courseLogo}
                setFile={setCourseLogo}
                error={error?.coursLogo}
              />



              <TextField
                id="outlined-basic"
                label="Description"
                variant="outlined"
                name="description"
                value={description}
                onInput={onInputChange}
                size="small"
                margin="dense"
                sx={{ width: '100%' }}
              />

              <MultipleSelectCheckmarks
                name={'Modules'}
                data={options.modules}
                currentId={modules}
                fetchValue={changeModules}
                width={400}
              />

              <TextField
                id="outlined-basic"
                label="Count of Video"
                variant="outlined"
                name="count_video"
                value={count_video}
                onInput={onInputChange}
                size="small"
                margin="dense"
                sx={{ width: '100%' }}
              />
              <TextField
                id="outlined-basic"
                label="Duration of the course"
                variant="outlined"
                name="course_duration"
                value={course_duration}
                onInput={onInputChange}
                size="small"
                margin="dense"
                sx={{ width: '100%' }}
              />

              <TextField
                id="outlined-basic"
                label="Number of classes per week"
                variant="outlined"
                name="per_week"
                value={per_week}
                onInput={onInputChange}
                size="small"
                margin="dense"
                sx={{ width: '100%' }}
              />
              <Wrapper>
                <MultiList
                  value={suitable_for_those_who}
                  onChange={onChangeIterableList('suitable_for_those_who')}
                  onDelete={onDeleteItemFromIterableList('suitable_for_those_who')}
                  label={"Suitable for those who"}
                />
              </Wrapper>

              <FileLoader
                title={'Завантажити відео огляду'}
                file={videoOverview}
                setFile={setVideoOverview}
                error={error?.videoOverview}
              />

              <Wrapper>
                <MultiList
                  value={course_overview}
                  onChange={onChangeIterableList('course_overview')}
                  onDelete={onDeleteItemFromIterableList('course_overview')}
                  label={"Course overview"}
                />
              </Wrapper>

              <TextArea
                value={talking}
                onChange={setTalking}
                placeholder={'Enter a Talking'}
                label={'Talking'}
              />
            </WrapperForm>
          </FormContext>

          <Logic data={initData} handelDelete={handelDelete} />
        </Form>
      </ModalBasic>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  margin-top: 10px;

  flex: 1 1 0;

  .ql-container {
    min-height: 70px;
    max-height: 70px
   }
`;

const FormContext = styled.div`
  margin: 20px -20px 20px 0; 
  
`
const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  margin-right: 20px;
`

const Title = styled.strong`
  display: block;
  font-size: 18px;
  line-height: 1;
`;

const Wrapper = styled.div`
  margin: 10px;
  width: 100%;
`;

