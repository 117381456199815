import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router';
import operation from '@redux/auth/auth-operation';
import authSelectors from '@redux/auth/auth-selectors';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthModal } from '@root/components';

export const VerifyEmailPage = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loadingVerify } = useSelector(authSelectors.getLoading);
  const isVerify = useSelector(authSelectors.getIsVerify);
  useEffect(() => {
    dispatch(operation.verifyEmail({ token, navigate }));
  }, [dispatch, navigate, token]);

  return (
    <LoginWrap>
      <AuthModal
        text={
          isVerify ? (
            <Text>Ми авторизовуємо ваш акаунт будь ласка зачекайте!</Text>
          ) : (
            <Text>Помилка аунтефікації будь ласка спробуйте знову!</Text>
          )
        }
      >
        {loadingVerify && (
          <Wrapper>
            <CircularProgress />
          </Wrapper>
        )}
      </AuthModal>
    </LoginWrap>
  );
};

const LoginWrap = styled.div`
  display: flex;
  background-color: grey;
  height: 100vh;
  width: 100vw;
`;
const Text = styled.span`
  display: block;
  text-align: center;
`;

const Wrapper = styled.strong`
  margin: 50px 0;
`;
