
import { useState } from 'react';
import { List, ListItem } from '@mui/material';
import { CardItem } from '@root/components';
import { AccordionModulesCard } from './AccordionModulesCard'
import { useParams, useNavigate } from 'react-router';

export const ModulesDetails = ({ modules }) => {
  const [openId, setOpenId] = useState(null)
  const navigate = useNavigate();
  const { courseId } = useParams();

  return (<>
    <List>
      {modules.map((el, i) => (
        <ListItem
          sx={{ display: 'block' }}
          onClick={() => {
            if (el._id === openId) {
              setOpenId(null)
            } else {
              setOpenId(el._id)
            }
          }}
        >
          <AccordionModulesCard data={el} index={i} open={el._id === openId} />
          {el._id === openId && (
            <List>
              {el.lessons.map((lesson, idx) => (
                <ListItem
                  key={el._id}
                  sx={{ display: 'block' }}
                  onClick={() => navigate(`/${courseId}/${lesson._id}/video`)}
                >
                  <CardItem data={lesson} index={idx} />
                </ListItem>
              ))}
            </List>
          )}
        </ListItem>
      ))}
    </List>
  </>)
}

