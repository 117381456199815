import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import operation from '@redux/admin/admin-operation';
import adminSelectors from '@redux/admin/admin-selectors';
import { audioConf } from '@root/conf/tableConf';
import { DenseTable, FormAudio } from '@root/components';
import styled from 'styled-components';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import IconButton from '@mui/material/IconButton';

export const AudioTabs = () => {
  const [isModal, setIsModal] = useState(false);
  const audio = useSelector(adminSelectors.getAudio);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(operation.getAudios());
  }, [dispatch]);

  const openModal = () => {
    setIsModal(true);
  };

  const closeModal = () => {
    setIsModal(false);
  };

  const menuConf = [
    {
      label: () => 'Delete',
      key: 'base',
      type: 'modal',
      onClickHandel: (audioId) => dispatch(operation.deleteAudios({ audioId }))
    },

  ];
  return (
    <>
      <TableWrapper>
        <DenseTable
          conf={audioConf}
          data={audio}
          menuConf={menuConf}
        />
      </TableWrapper>
      <IconWrapper>
        <IconButton aria-label="add" onClick={openModal}>
          <AddCircleOutlineOutlinedIcon color="primary" fontSize="large" />
        </IconButton>
      </IconWrapper>

      {isModal && <FormAudio handleClose={closeModal} />}
    </>
  );
};

const TableWrapper = styled.div`
  margin: 50px 0;
`;

const IconWrapper = styled.div`
  position: fixed;
  bottom: 40px;
  right: 20px;
`;
