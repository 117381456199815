import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Typography } from '@mui/material';
import style from 'styled-components';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';

const AvatarComponent = style(Avatar)`
  cursor: pointer; 
  z-index: 1;
  position: relative; 
  overflow: hidden;
  transition: transform 0.3s ease-in-out;

`;

const FileInput = style.input`
  display: none;
`;

const AvatarLink = style.div`
  display: flex;
  align-items: center;
  flex-direction: column; 
  text-decoration: none;
  gap: 20px;
  position: relative; /* Добавлено позиционирование */

  &:hover {
    text-decoration: none;
  }
`;

const UploadIco = style(FileUploadRoundedIcon)`
color: #757575; /* Серый цвет */
width: 20px;
height: 20px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
`;

const AvatarOverlay = style.div`
  position: absolute;
  z-index: 12;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 160px; /* Позиционирование вверху */
  right: 18px; /* Позиционирование слева */
  background-color: #fff;
  background-image: ${UploadIco} center;
  border: 2px solid #757575;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;

  ${AvatarLink}:hover & {
    opacity: 1;
  }
`;

const AvatarUpload = ({ avatar = '', file, name, onChange }) => {
  const handleAvatarClick = () => {
    const fileInput = document.getElementById('file-input');
    fileInput.click();
  };

  return (
    <AvatarLink
      sx={{
        position: 'relative',
      }}
    >
      <AvatarComponent
        alt="User Avatar"
        src={file ? URL.createObjectURL(file) : avatar}
        onClick={handleAvatarClick}
        sx={{
          width: 200,
          height: 200,
          fontSize: '56px',
        }}
      >
        {file ? null : name.charAt(0)}
      </AvatarComponent>
      <AvatarOverlay>
        <UploadIco />
      </AvatarOverlay>
      <Typography
        variant="subtitle1"
        sx={{
          color: '#000',
          fontSize: '16px',
          lineHeight: '20px',
        }}
      >
        {name}
      </Typography>
      <FileInput
        type="file"
        id="file-input"
        name="avatar"
        onChange={onChange}
        accept={`.jpg,.png,.jpeg,`}
      />
    </AvatarLink>
  );
};

export default AvatarUpload;
