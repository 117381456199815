import { createSlice } from '@reduxjs/toolkit';
import operation from './admin-operation';

const initialState = {
  users: [],
  courses: [],
  modules: [],
  lessons: [],
  audio: [],
  isLoading: false,
};

const adminSlice = createSlice({
  name: 'home',
  initialState,
  extraReducers: {
    //==============================================Users=================================================================//
    //====getAllUsers====//
    [operation.getAllUsers.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.getAllUsers.fulfilled]: (state, { payload }) => {
      state.users = payload;
      state.isLoading = true;
    },
    [operation.getAllUsers.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    //====updateSubscription====//
    [operation.updateSubscription.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.updateSubscription.fulfilled]: (state, { payload }) => {
      state.users = payload;
      state.isLoading = true;
    },
    [operation.updateSubscription.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    //====deleteUser====//
    [operation.delateUser.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.delateUser.fulfilled]: (state, { payload }) => {
      state.users = payload;
      state.isLoading = true;
    },
    [operation.delateUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    //==============================================Course=================================================================//
    //====createCourse====//
    [operation.createCourse.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.createCourse.fulfilled]: (state, { payload }) => {
      state.courses = [...state.courses, payload];
      state.isLoading = true;
    },
    [operation.createCourse.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    //====getAllCourse====//
    [operation.getAllCourse.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.getAllCourse.fulfilled]: (state, { payload }) => {
      state.courses = payload;
      state.isLoading = true;
    },
    [operation.getAllCourse.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    //====updateCourseInUser====//
    [operation.updateCourseInUser.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.updateCourseInUser.fulfilled]: (state, { payload }) => {
      state.users = payload;
      state.isLoading = true;
    },
    [operation.updateCourseInUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    //====updateCourse====//
    [operation.updateCourse.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.updateCourse.fulfilled]: (state, { payload }) => {
      state.courses = payload;
      state.isLoading = true;
    },
    [operation.updateCourse.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    //====deleteCourse====//
    [operation.deleteCourse.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.deleteCourse.fulfilled]: (state, { payload }) => {
      state.courses = payload;
      state.isLoading = true;
    },
    [operation.deleteCourse.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    //====updateCourseTariff====//
    [operation.updateCourseTariff.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.updateCourseTariff.fulfilled]: (state, { payload }) => {
      state.courses = payload;
      state.isLoading = true;
    },
    [operation.updateCourseTariff.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    //====updateCourseActive====//
    [operation.updateCourseActive.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.updateCourseActive.fulfilled]: (state, { payload }) => {
      state.courses = payload;
      state.isLoading = true;
    },
    [operation.updateCourseActive.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    //====updateCoursePublish====//
    [operation.updateCoursePublish.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.updateCoursePublish.fulfilled]: (state, { payload }) => {
      state.courses = payload;
      state.isLoading = true;
    },
    [operation.updateCoursePublish.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    //==============================================Modules=================================================================//
    //====getAllModules====//
    [operation.getAllModules.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.getAllModules.fulfilled]: (state, { payload }) => {
      state.modules = payload;
      state.isLoading = true;
    },
    [operation.getAllModules.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    //====createModules====//
    [operation.createModules.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.createModules.fulfilled]: (state, { payload }) => {
      state.modules = payload;
      state.isLoading = true;
    },
    [operation.createModules.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    //====updateModules====//
    [operation.updateModules.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.updateModules.fulfilled]: (state, { payload }) => {
      state.modules = payload;
      state.isLoading = true;
    },
    [operation.updateModules.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    //====deleteModules====//
    [operation.deleteModules.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.deleteModules.fulfilled]: (state, { payload }) => {
      state.modules = payload;
      state.isLoading = true;
    },
    [operation.deleteModules.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    //==============================================Lessons=================================================================//

    //====createLesson====//
    [operation.createLesson.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.createLesson.fulfilled]: (state, { payload }) => {
      state.lessons = [...state.lessons, payload];
      state.isLoading = true;
    },
    [operation.createLesson.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    //====getAllLessons====//
    [operation.getAllLessons.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.getAllLessons.fulfilled]: (state, { payload }) => {
      state.lessons = payload;
      state.isLoading = true;
    },
    [operation.getAllLessons.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    //====updateLesson====//
    [operation.updateLesson.pending]: (state, action) => {
      state.isLoading = false;
    },

    [operation.updateLesson.fulfilled]: (state, { payload }) => {
      state.lessons = payload;
      state.isLoading = true;
    },

    [operation.updateLesson.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    //====deleteLesson====//
    [operation.deleteLesson.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.deleteLesson.fulfilled]: (state, { payload }) => {
      state.lessons = payload;
      state.isLoading = true;
    },
    [operation.deleteLesson.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    //====getAudios====//
    [operation.getAudios.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.getAudios.fulfilled]: (state, { payload }) => {
      state.audio = payload;
      state.isLoading = true;
    },
    [operation.getAudios.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    //====createAudios====//
    [operation.createAudios.pending]: (state, action) => {
      state.isLoading = false;
    },

    [operation.createAudios.fulfilled]: (state, { payload }) => {
      state.audio = payload;
      state.isLoading = true;
    },

    [operation.createAudios.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    //====deleteAudios====//
    [operation.deleteAudios.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.deleteAudios.fulfilled]: (state, { payload }) => {
      state.audio = payload;
      state.isLoading = true;
    },
    [operation.deleteAudios.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});
export default adminSlice.reducer;
