const getUserName = state => state.auth.userName;
const getUserEmail = state => state.auth.userEmail;
const getRole = state => state.auth.role;
const getIsLoggedGoogleIn = state => state.auth.isLoggedGoogleIn;
const getUserToken = state => state.auth.token;
const getIsRefreshing = state => state.auth.isRefreshing;
const getLoading = state => state.auth.loading;
const getIsVerify = state => state.auth.isVerify;
const getIsRegister = state => state.auth.isRegister;
const getUserId = state => state.auth.userId;
const getValidationRememberToken = state => state.auth.validationRememberToken;
const getAvatar = state => state.auth.avatar;

const authSelectors = {
  getUserName,
  getUserEmail,
  getRole,
  getIsLoggedGoogleIn,
  getUserToken,
  getIsRefreshing,
  getLoading,
  getIsVerify,
  getIsRegister,
  getUserId,
  getValidationRememberToken,
  getAvatar,
};
export default authSelectors;
