import * as React from 'react';
import { Box, Modal } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,

  maxHeight: "70vh",
  overflow: "hidden auto",
  scrollbarWidth: "thin",

  ":: -webkit-scrollbar": {
    width: "5px"
  },

  ":: -webkit-scrollbar-thumb": {
    background: "#c5c8d2",
    borderRadius: "4px"
  },

  "::-webkit-scrollbar-track": {
    background: "#fff",
    borderLeft: "1px solid #dbdee9"
  }

};

export const ModalBasic = ({ handleClose, isDubbleSize = false, children, styled = {} }) => {
  return (
    <>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{
            minWidth: isDubbleSize ? '700px' : '400px',
            maxWidth: '95vw',
            overflow: 'auto',
            ...styled
          }}
        >
          {children}
        </Box>
      </Modal>
    </>
  );
};
