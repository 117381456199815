import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';
import operation from '@redux/admin/admin-operation';
import operationOptions from '@redux/options/options-operation';
import adminSelectors from '@redux/admin/admin-selectors';
import optionsSelectors from '@redux/options/options-selectors';
import { usersConf } from '@root/conf/tableConf';
import { DenseTable } from '@root/components';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { useDebounce } from '@root/hooks';

export const UsersTabs = () => {
  const [search, setSearch] = useState('');
  const users = useSelector(adminSelectors.getUsers);
  const options = useSelector(optionsSelectors.getOptions);
  const debounceSearchValue = useDebounce(search, 400);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(operationOptions.getOptions());
  }, [dispatch]);

  useEffect(() => {
    dispatch(operation.getAllUsers({ q: debounceSearchValue }));
  }, [debounceSearchValue]);



  return (
    <>
      <TextField
        id="outlined-basic"
        label="Search"
        variant="outlined"
        name="email"
        value={search}
        onInput={e => setSearch(e.target.value.trim())}
        size="small"
        margin="dense"
        autoComplete="off"
        sx={{ width: '100% ', mt: '10px' }}
      />

      <TableWrapper>
        <DenseTable conf={usersConf} data={users} options={options} />
      </TableWrapper>
    </>
  );
};

const TableWrapper = styled.div`
  margin: 10px 0;
`;
