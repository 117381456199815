export const GamesBody = ({ data }) => {
  const { type, id } = data;
  switch (type) {
    case 'learningapps':
      return (
        <iframe
          src={`https://learningapps.org/watch?app=${id}`}
          width="100%"
          height="100%"
        ></iframe>
      );
    case 'wordwall':
      return (
        <iframe
          src={`https://wordwall.net/embed/${id}`}
          width="100%"
          height="100%"
        ></iframe>
      );

    case 'h5p':
      return (
        <iframe
          src={`https://h5p.org/h5p/embed/${id}`}
          width="100%"
          height="100%"
          frameborder="0"
          allowfullScreen="allowfullscreen"
          allow="geolocation *; microphone *; camera *; midi *; encrypted-media *"
          title="Advent Calendar (beta)"
        ></iframe>
      );
    default:
      break;
  }
};
