import { createSlice } from '@reduxjs/toolkit';
import operation from './user-operation';

const initialState = {
  userInfo: {},

  isLoading: false,
};

const userReducer = createSlice({
  name: 'home',
  initialState,
  extraReducers: {
    //==============================================Users=================================================================//
    //====getUserInfo====//

    [operation.getUserInfo.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.getUserInfo.fulfilled]: (state, { payload }) => {
      state.userInfo = payload;
      state.isLoading = true;
    },
    [operation.getUserInfo.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    //====changeUserInfo====//

    [operation.changeUserInfo.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.changeUserInfo.fulfilled]: (state, { payload }) => {
      state.userInfo = payload;
      state.isLoading = true;
    },
    [operation.changeUserInfo.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});
export default userReducer.reducer;
