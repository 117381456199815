import { createSlice } from '@reduxjs/toolkit';
import operation from './options-operation';

const initialState = {
  options: {},
  isLoading: false,
};

const optionsSlice = createSlice({
  name: 'home',
  initialState,
  extraReducers: {
    //====getOptions====//
    [operation.getOptions.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.getOptions.fulfilled]: (state, { payload }) => {
      state.options = payload;
      state.isLoading = true;
    },
    [operation.getOptions.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});
export default optionsSlice.reducer;
