import ReactQuill from 'react-quill';
import styled from 'styled-components';

export const TextArea = ({
  onChange,
  value,
  style = {
    width: '100%',
    marginTop: '10px',
  },
  label,
  placeholder
}) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <ReactQuill
        value={value}
        onChange={onChange}
        formats={['header', 'bold', 'italic', 'underline']}
        modules={{ toolbar: [['bold', 'italic']] }}
        placeholder={placeholder}
        style={style}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position:relative;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #c5c8d2;
`;


const Label = styled.label`
  position: absolute;
  top: -10px;
  left: 10px;

  padding: 1px 10px;
  color: #3498db;
  background: #fff;


  text-align: start;
  font-size: 11px;
  font-weight: 600;

`