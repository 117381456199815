import { ModalBasic, Logic, MultiSetMenu } from '@root/components';
import { useState } from 'react';
import styled from 'styled-components';
import operations from '@root/redux/admin/admin-operation';
import { useDispatch } from 'react-redux';
import { serialize } from 'object-to-formdata';
import { FormHelperText } from '@mui/material';

export const FormAudio = ({ handleClose, data }) => {
  const [audioFile, setAudioFile] = useState([]);
  const dispatch = useDispatch();
  const [error, setError] = useState({ init: false, text: '' });

  const handelSubmit = e => {
    e.preventDefault();

    if (!validation()) {
      const sendDate = serialize(
        {
          audio: audioFile,
        },
        {
          indices: true,
          nullsAsUndefineds: true,
          booleansAsIntegers: true,
          noFilesWithArrayNotation: true,
        },
      );
      dispatch(operations.createAudios(sendDate)).then(el => handleClose());

    }
  };

  const validation = () => {
    if (audioFile.length === 0) {
      setError({ init: true, text: 'Required' });
      return true;
    }

    setError({ init: false, text: '' });
    return false;
  };

  return (
    <ModalBasic handleClose={handleClose}>
      <FlexContainer>
        <Form onSubmit={handelSubmit}>
          <Title>Create the Audio</Title>

          <MultiSetMenu
            file={audioFile}
            setFile={setAudioFile}
            handelDelete={() => { }}
            handelUpdate={() => { }}
            accept=".mp3"
            sx={{ marginTop: '5px' }}
          />
          {error?.init && (
            <FormHelperText
              error={true}
              id="component-error-text"
              sx={{ display: 'flex', mr: 'auto' }}
            >
              {error?.text}
            </FormHelperText>
          )}
          <Logic data={data} />
        </Form>
      </FlexContainer>
    </ModalBasic>
  );
};

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 0;

  margin-top: 10px;

  button {
    margin-top: 10px;
  }
`;

const Title = styled.strong`
  display: block;
  font-size: 18px;
  line-height: 1;
`;

