import { useParams, useLocation } from 'react-router';
import { GamesBody } from '@root/components';
import styled from 'styled-components';

export const Games = () => {
  const params = useParams();
  const location = useLocation();
  return (
    <Wrap>
      <GamesBody
        data={{ type: params.gameType, id: location.search.split('id=')[1] }}
      ></GamesBody>
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 100%;
  height: 85vh;
`;
