import styled from 'styled-components';
import * as React from 'react';
import Author from '../../../src/assets/photo-with-book.png';


import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import FacebookIcon from '@mui/icons-material/Facebook';
import DraftsIcon from '@mui/icons-material/Drafts';

export const Contact = () => (
  <Box>
    <Img src={Author} alt='Author' />
    <WrapperDescription>
      <WrapperText>
        <Title>Про мене</Title>
        <SubTitle>
          Усім привіт! Я Леся — викладач португальської та засновниця
          онлайн-школи Portuguese Vibe School
        </SubTitle>
        <Paragraf>
          Найбільш яскравий та переломний момент мого життя — навчання за
          програмою обміну в Португалії: Universidade do Porto та Universidade
          de Lisboa.{' '}
        </Paragraf>
        <Paragraf>
          А вже у 2022 році я підтвердила рівень С1, склавши екзамен DAPLE.
        </Paragraf>
        <Paragraf>
          Улюблена частина моєї роботи — методична розробка освітніх курсів та
          їх проведення.{' '}
        </Paragraf>
      </WrapperText>
      <WrapperAddress>
        <Address>
          <Link
            href="http://www.instagram.com/portuguese_vibe/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <InstagramIcon />
            </span>
            <Text>Instagram</Text>
          </Link>
          <Link
            href="http://t.me/Lesia_Parashchyniak"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            <span>
              <TelegramIcon />
            </span>
            <Text>Telegram</Text>
          </Link>
          <Link
            href="http://www.facebook.com/groups/708293430281572/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            <span>
              <FacebookIcon />
            </span>
            <Text>Facebook</Text>
          </Link>
          <Link href="mailto:portuguesevibe@gmail.com">
            {' '}
            <span>
              <DraftsIcon />
            </span>
            <Text>Email</Text>
          </Link>
        </Address>
        <CircleList>
          <CircleItem>
            <CircleNumber> 120+ </CircleNumber>
            учнів пройшли 1-й потік курсу А1
          </CircleItem>
          <CircleItem>
            <CircleNumber>100+</CircleNumber>
            студентам допомогла вивчити мову
          </CircleItem>
          <CircleItem>
            <CircleNumber>8+</CircleNumber>
            років досвіду у викладанні
          </CircleItem>
        </CircleList>
      </WrapperAddress>
    </WrapperDescription>
  </Box>
);


const Box = styled.section`
  font-family: Montserrat;
  color: #333333;
  margin: 60px 10px 0px 10px;
  gap: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 420px) {
    margin: 60px 30px;
  }

  @media screen and (min-width: 1200px) {
    gap: 50px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  @media screen and (min-width: 1600px) {
    gap: 150px;
  }
`;

const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  max-width: 578px;
`;

const WrapperAddress = styled.div`
    display: block;
    padding: 20px 0px 30px 0px;
  
  @media screen and (min-width: 1200px) {
    display: flex;
  }
`

const Address = styled.address`
  display: flex;
  justify-content: center;
  font-style: normal;

  @media screen and (min-width: 1200px) {
    display: block;
  }
`;

const Link = styled.a`
  color: #de5736;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 40px;

    @media (min-width: 1200px) {
      display: none;
    }
  }
`;

const Text = styled.p`
  color: #de5736;
  padding: 15px 0px;
  font-size: 24px;
  text-decoration: underline;
  display: none;

  @media screen and (min-width: 1200px) {
    display: flex;
  }
`;

const Paragraf = styled.p`
  padding-bottom: 40px;
  font-size: 16px;

  @media screen and (min-width: 1200px) {
    font-size: 18px;
  }
`;

const CircleNumber = styled.span`
  font-family: Cormorant;
  display: block;
  text-decoration: none;
  font-weight: 500;
  font-size: 40px;
  line-height: 1.5;
`;

const SubTitle = styled.h4`
  font-size: 20px;
  padding: 40px 0px 20px 0px;
  line-height: 1.7rem;
  font-weight: normal;
  
  @media screen and (min-width: 1200px) {
    font-size: 23px;
    padding: 60px 0px 40px 0px;
  }
`;

const WrapperDescription = styled.div`
  @media screen and (min-width: 1200px) {
    max-width: 728px;
  }
`

const WrapperText = styled.div`
`;

const Title = styled.h2`
  font-family: Cormorant;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  padding-top: 40px;
  text-align: center;

  @media screen and (min-width: 1200px) {
    font-size: 48px;
    text-align: start;
    padding-top: 0px;
  }
`;

const CircleList = styled.div`
  display: none;
  
  @media screen and (min-width: 1500px) {
    display: flex;
  }
`

const CircleItem = styled.div`
  display: block;
  position: relative;
  border-radius: 50%;
  width: 163px;
  height: 163px;
  padding: 30px;
  background-color: transparent;
  color: #de5736;
  border: 1px solid #de5736;
  font-size: 12px;
  text-align: center;
  line-height: 0.9;
  
  &:first-child {
    background-color: #de5736;
    color: #fcfcfc;
    margin-left: 180px;
  }
  
  &:nth-child(2n) {
    margin-left: -30px;
    margin-top: 50px;
  }
 
  &:nth-child(3n) {
    margin-left: -30px;
    margin-top: 100px;
    padding: 38px 36px;
  }
  
  @media screen and (min-width: 1200px) {
    display: block;
  }
`;
