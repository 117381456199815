import {
  ModalBasic,
  Logic,
  MultipleSelectCheckmarks,
} from '@root/components';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import operations from '@root/redux/admin/admin-operation';

export const FormModule = ({ handleClose, data, options }) => {
  const [title, setTitle] = useState('');
  const [lessons, setLessons] = useState([]);
  const [error, setError] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      const { name, lessons } = data;
      setTitle(name);
      setLessons([...lessons]);
    }
  }, [data]);

  const handelSubmit = e => {
    e.preventDefault();

    if (!validation()) {
      if (data) {
        dispatch(
          operations.updateModules({
            id: data?._id,
            lessons,
            name: title,
          }),
        );
      } else {
        dispatch(operations.createModules({ name: title, lessons }));
      }

      handleClose();
    }
  };

  const handelDelete = e => {
    dispatch(operations.deleteModules({ id: data._id }));
    handleClose();
  };

  const validation = () => {
    if (!title || title === '') {
      setError({ title: { init: true, text: 'Required' } });
      return true;
    }
    setError({});
    return false;
  };

  const onInputChange = e => {
    switch (e.target.name) {
      case 'title':
        setTitle(e.target.value);
        break;
      default:
        break;
    }
  };

  const changeLessons = arr => setLessons([...arr]);

  return (
    <>
      <ModalBasic handleClose={handleClose}>
        <Form onSubmit={handelSubmit}>
          <Title>{data ? 'Edit' : 'Create'} the Module</Title>
          <TextField
            error={error?.title?.init}
            helperText={error?.title?.text}
            id="outlined-basic"
            label="Title"
            variant="outlined"
            name="title"
            value={title}
            onInput={onInputChange}
            size="small"
            margin="dense"
            sx={{ mt: '10px', width: "100%" }}
          />

          <MultipleSelectCheckmarks
            name={'Lessons'}
            data={options.lessons}
            currentId={lessons}
            fetchValue={changeLessons}
            sx={{ mt: '10px' }}
          />

          <Logic data={data} handelDelete={handelDelete} />
        </Form>
      </ModalBasic>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 10px;

  flex: 1 1 0;

  button {
    margin-top: 10px;
  }
`;

const Title = styled.strong`
  display: block;
  font-size: 18px;
  line-height: 1;
`;

const Wrap = styled.div`
  width: 209px;
`;
