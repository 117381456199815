import { createSlice } from '@reduxjs/toolkit';
import operation from './home-operation';

const initialState = {
  course: [],
  modules: [],
  lesson: {},
  courseName: '',
  isLoading: false,
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  extraReducers: {
    //====getCourse====//
    [operation.getCourse.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.getCourse.fulfilled]: (state, { payload }) => {
      state.course = payload;
      state.isLoading = true;
    },
    [operation.getCourse.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    //====getCourseById====//
    [operation.getCourseById.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.getCourseById.fulfilled]: (state, { payload }) => {
      state.modules = payload;
      //state.courseName = payload.courseName;
      state.isLoading = true;
    },
    [operation.getCourseById.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    //====getLessonsByID====//
    [operation.getLessonsByID.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.getLessonsByID.fulfilled]: (state, { payload }) => {
      state.lesson = payload;
      state.isLoading = true;
    },
    [operation.getLessonsByID.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});

export default homeSlice.reducer;
