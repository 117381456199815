export const theme = {
  pallet: {
    primary: '#545AE2',
    primaryTransparent: 'rgba(84, 90, 226, 0.4)',
    primaryBlue: '#3f45da',
    greyTransparent: `rgba(21, 25, 32, 0.5)`,
    white: '#ffffff',
    whiteTransparent: `rgba(255, 255,255, 0.4)`,
    dark: '#393939',
    black: '#000000',
    darkTransparent: 'rgba(0, 0, 0, 0.4)',
    'dark-100': '#2E3143',
    medium: '#4A4E62',
    grey: '#F8F9FA',
    'grey-100': '#F4F5F7',
    lightGrey: '#7C7F91',
    neutralMedium: '#D6D7DC',
    inactive: '#ADB0BD',
    red: '#BE1717',
    transparent: 'transparent',
    neutral: '#DCE1E7',
    light: '#7C7F90',
    lightGray: '#fbfbfb',

    disabledColor: 'rgba(84, 90, 226, 0.3)',
    yellow: '#ffde59',
    green: '#649b63',
  },
  palletAccent: {
    primaryAccentHover: 'rgba(84, 90, 226, 0.05)',
    primaryActivePress: 'rgba(84, 90, 226, 0.2)',
    secondaryAccentHover: '#F4F5F7',
    secondaryActivePress: '#DCE1E7',
    thirdActivePress: 'rgba(84, 90, 226, 0.1)',
  },
  weights: {
    bold: 700,
    semiBold: 600,
    regular: 400,
    think: 300,
  },
  sizes: {
    small: 12,
    regular: 14,
    medium: 16,
    extra_medium: 19,
    big: 20,
    extraBig: 28,
    huge: 32,
  },
  borderRadius: {
    tiny: 4,
    small: 8,
    medium: 12,
    semiMedium: 16,
    big: 20,
  },
  alignItems: {
    center: 'center',
    start: 'start',
    end: 'end',
  },


  contentPointsTerms: {
    mobile: '375px',
    tablet: '540px',
    tabletUp: `760px`,
    laptop: '960px',
    laptopUp: `1024px`,
    desktopHeader: '1280px',
    desktopUP: '2560px',
  },
};
