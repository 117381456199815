import { useState, useEffect } from 'react';

import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  FormHelperText,
  Box,
} from '@mui/material';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const MultipleSelectCheckmarks = ({
  name,
  data = [],
  currentId = [],
  fetchValue,
  helperText,
  error,
  sx,
  width = '100%',
}) => {
  const [value, setValue] = useState([]);

  useEffect(() => {
    setValue([...currentId]);
  }, [currentId]);

  const handleChange = event => {
    const {
      target: { value },
    } = event;

    setValue(typeof value === 'string' ? value.split(',') : value);
    if (fetchValue)
      fetchValue(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <Box sx={{ width, ...sx }}>
      <FormControl error={error} sx={{ width }}>
        <InputLabel id="demo-multiple-checkbox-label">{name}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={value}
          onChange={handleChange}
          input={<OutlinedInput label={name} />}
          renderValue={selected => {

            const res = selected.reduce((acc, el) => {
              const item = data.find(dataEl => dataEl._id === el)
              if (item) acc = acc + (acc.length !== 0 ? ',' : '') + item?.name
              return acc
            }, "")
            return res || null
          }}
          MenuProps={MenuProps}
        >
          {[...data].map(({ name, _id }, i) => {
            return (
              <MenuItem key={i} value={_id}>
                <Checkbox checked={value.indexOf(_id) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            );
          })}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
};
