import { Fragment } from 'react';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import { Items } from './Items'

export const MultiList = ({ value, onChange, label, onDelete }) => {

  const handelClick = () => {
    onChange('', value.length)
  }



  return (
    <Wrapper>
      <Label>{label}</Label>
      {value.length > 0 ?
        (
          <>
            <List>
              {value.map((el, idx) => (
                <Fragment key={label + idx}>
                  <Items
                    value={el}
                    idx={idx}
                    onChange={onChange}
                    onDelete={onDelete}
                    label={label}
                  />
                </Fragment>
              ))}
            </List>
          </>
        ) : (
          <Strong>Cписок порожній</Strong>
        )
      }
      <ButtonAdd type="button" onClick={handelClick}>
        <AddIcon />
      </ButtonAdd>
    </Wrapper>)
}


const Wrapper = styled.div`
  position: relative;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #c5c8d2;
`;

const Label = styled.label`
  position: absolute;
  top: -10px;
  left: 10px;

  padding: 1px 10px;
  color: #3498db;
  background: #fff;


  text-align: start;

  font-size: 11px;
  font-weight: 600;

`

const ButtonAdd = styled.button`
  margin-top: 10px;
  width: 100%;
  cursor: pointer;
  border: 1px solid #000;
`;

const List = styled.ul`
  width: 100%;

  li+li{
    margin-top: 5px;
  }
`;

const Strong = styled.strong`
  margin: 10px 0;
`;