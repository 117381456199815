import { ColorTabs, Spinner } from '@root/components';
import { tabsConf } from '@root/conf/adminConf';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import adminSelectors from '@redux/admin/admin-selectors';
import styled from 'styled-components';

export const Admin = () => {
  const { tab } = useParams();
  const loading = useSelector(adminSelectors.isLoading);
  const getMurkUp = () => {
    const response = tabsConf.find(el => el.value === tab);
    return response.element;
  };
  return (
    <Wrapper>
      <ColorTabs conf={tabsConf} path={"/admin"} />
      {getMurkUp()}
      {!loading && <Spinner />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 10px;
`;
