const getUsers = state => state.admin.users;
const getCourse = state => state.admin.courses;
const getLessons = state => state.admin.lessons;
const getModule = state => state.admin.modules;
const getAudio = state => state.admin.audio;
const isLoading = state => state.admin.isLoading;

const adminSelectors = {
  getUsers,
  getCourse,
  getLessons,
  getModule,
  getAudio,
  isLoading,
};

export default adminSelectors;
