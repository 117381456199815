import styled from 'styled-components';
import Button from '@mui/material/Button';

export const Logic = ({ data, handelDelete }) => {
  return (
    <>
      {data ? (
        <FlexContainer>
          <Button
            type="button"
            variant="outlined"
            color="error"
            onClick={handelDelete}
          >
            Delete
          </Button>

          <Button type="submit" variant="outlined">
            Update
          </Button>
        </FlexContainer>
      ) : (
        <Button type="submit" variant="outlined">
          Create
        </Button>
      )}
    </>
  );
};

const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
