import { useState } from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CustomToolbar = ({ onSubmit, disableSubmit }) => (
  <CustomToolbarWrapper id="toolbar">
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <WrapperBtn>
      <ButtonSubmit type="button" onClick={onSubmit} disabled={disableSubmit}>
        Submit
      </ButtonSubmit>
    </WrapperBtn>
  </CustomToolbarWrapper>
);

export const Items = ({ value, idx, onChange, onDelete, label }) => {
  const [edit, setEdit] = useState(false);
  const modules = {
    toolbar: {
      container: '#toolbar',
    },
  };

  const isEmptyHtmlTags = (html) => {
    // Регулярное выражение для поиска только пустых HTML-тегов
    const regex = /<[^\/>][^>]*><\/[^>]+>/g;
    return regex.test(html);
  };

  return (
    <Wrapper>
      {edit ? (
        <div className="text-editor">
          <CustomToolbar onSubmit={() => {
            if (value.length > 0) {
              setEdit(false)
            }
          }} disableSubmit={value.length === 0} />
          <ReactQuill
            value={value}
            onChange={value => onChange(value, idx)}
            modules={modules}
            placeholder={`Enter a ${label}`}
            style={{
              width: '100%',
            }}
          />
        </div>
      ) : (
        <Card>
          <p dangerouslySetInnerHTML={{ __html: value.length === 0 || isEmptyHtmlTags(value) ? "Empty Blok" : value }} />
          <div>
            <IconButton
              aria-label="upload"
              size="small"
              onClick={() => setEdit(true)}
            >
              <ModeEditIcon />
            </IconButton>
            <IconButton
              aria-label="upload"
              size="small"
              onClick={() => onDelete(idx)}
            >
              <DeleteForeverIcon />
            </IconButton>
          </div>

        </Card>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.li`
  .ql-editor {
    min-height: 50px;
    max-height: 50px;
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid black;
  background: #fff;
  padding: 5px;
`;

const CustomToolbarWrapper = styled.div`
  display: flex;
  align-items: center;
  button {
    margin: 0 !important;
  }
`;
const WrapperBtn = styled.div`
  margin-left: auto !important;
`;

const ButtonSubmit = styled.button`
  width: fit-content !important;
  background-color: #1976d2 !important;
  color: #fff !important;
  border-radius: 4px;
`;
