const getCourse = state => state.home.course;
const getModules = state => state.home.modules;
const getLesson = state => state.home.lesson;
const getNameCourse = state => state.home.courseName;

const homeSelectors = {
  getCourse,
  getModules,
  getLesson,
  getNameCourse,
};

export default homeSelectors;
