import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const Redirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    backHome();
  }, []);

  const backHome = () => {
    navigate('/');
  };
  return <></>;
};
