import { useState, useEffect } from 'react';
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
} from '@mui/material';
import { nanoid } from 'nanoid';

export const BasicSelect = ({
  name,
  data = [],
  currentId = '',
  fetchValue,
  helperText,
  error,
  isEmptyOptions = false,
  sx,
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (currentId !== ' ') setValue(currentId);
  }, [currentId]);

  const handleChange = event => {
    if (event.target.value === 'default') {
      setValue('');
      if (fetchValue) fetchValue(' ');
    } else {
      setValue(event.target.value);
      if (fetchValue) fetchValue(`${event.target.value}`);
    }
  };

  return (
    <Box sx={{ minWidth: 120, ...sx }}>
      <FormControl error={error} fullWidth>
        <InputLabel id="demo-simple-select-label">{name}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={name}
          onChange={handleChange}
        >
          {isEmptyOptions && (
            <MenuItem value={'default'} key={nanoid()}>
              Default
            </MenuItem>
          )}

          {data.map(el => (
            <MenuItem value={el._id} key={nanoid()}>
              {el.name}
            </MenuItem>
          ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
};
