import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

const getAllUsers = createAsyncThunk(
  'admin/getAllUsers',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('user', { params: payload });

      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const updateSubscription = createAsyncThunk(
  'admin/updateSubscription',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.patch('user/subscription', payload);
      toast.success(`Subscription update is successful`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const delateUser = createAsyncThunk(
  'admin/delateUser',
  async ({ userId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`user/${userId}`);
      toast.success(`User Delate successful`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

//======Course=======//
const getAllCourse = createAsyncThunk(
  'admin/getAllCourse',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('course/admin');
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const createCourse = createAsyncThunk(
  'admin/createCourse',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('course', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success(`Create Course is successful`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return rejectWithValue(error);
    }
  },
);

const updateCourseInUser = createAsyncThunk(
  'admin/updateCourseInUser',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put('user/course', payload);
      toast.success(`Update Course is successful`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return rejectWithValue(error);
    }
  },
);

const deleteCourse = createAsyncThunk(
  'admin/deleteCourse',
  async ({ courseId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`course/${courseId}`);
      toast.success(`Delete Course is successful`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return rejectWithValue(error);
    }
  },
);

const updateCourse = createAsyncThunk(
  'admin/updateCourse',
  async ({ courseId, sendDate }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(`course/${courseId}`, sendDate, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success(`Update Course is successful`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return rejectWithValue(error);
    }
  },
);

const updateCourseTariff = createAsyncThunk(
  'admin/updateCourseTariff',
  async ({ payload, courseId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(`course/${courseId}/tariff`, {
        ...payload,
      });
      toast.success(`Update Course Tariff is successful`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return rejectWithValue(error);
    }
  },
);
const updateCourseActive = createAsyncThunk(
  'admin/updateCourseActive',
  async ({ payload, courseId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(`course/${courseId}/active`, {
        ...payload,
      });
      toast.success(`Update Course status is successful`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return rejectWithValue(error);
    }
  },
);

const updateCoursePublish = createAsyncThunk(
  'admin/updateCoursePublish',
  async ({ payload, courseId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(`course/${courseId}/publish`, {
        ...payload,
      });
      toast.success(`Update Publish  of Course is successful`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return rejectWithValue(error);
    }
  },
);

//======Modules=======//
const getAllModules = createAsyncThunk(
  'admin/getAllModules',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('module');
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const createModules = createAsyncThunk(
  'admin/createModules',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('module', payload);
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const updateModules = createAsyncThunk(
  'admin/updateModules',
  async (payload, { rejectWithValue }) => {
    try {
      const { id } = payload;
      const { data } = await axios.put(`module/${id}`, payload);
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const deleteModules = createAsyncThunk(
  'admin/deleteModules',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`module/${id}`);
      toast.success(`Delete Modules is successful`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return rejectWithValue(error);
    }
  },
);

//======Lessons=======//
const getAllLessons = createAsyncThunk(
  'admin/getAllLessons',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('lesson');
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const createLesson = createAsyncThunk(
  'admin/createLesson',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('lesson', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success(`Create Lesson is successful`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return rejectWithValue(error);
    }
  },
);

const updateLesson = createAsyncThunk(
  'admin/updateLesson',
  async ({ lessonId, formData }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(`lesson/${lessonId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success(`Update Lesson is successful`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return rejectWithValue(error);
    }
  },
);

const deleteLesson = createAsyncThunk(
  'admin/deleteLesson',
  async ({ lessonId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`lesson/${lessonId}`);

      toast.success(`Delete Lesson is successful`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return rejectWithValue(error);
    }
  },
);

const getAudios = createAsyncThunk(
  'admin/getAudios',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`audio/`);
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return rejectWithValue(error);
    }
  },
);
const createAudios = createAsyncThunk(
  'admin/createAudios',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`audio/`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success(`Create Audios is successful`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return rejectWithValue(error);
    }
  },
);
const deleteAudios = createAsyncThunk(
  'admin/deleteAudios',
  async ({ audioId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`audio/${audioId}`);

      toast.success(`Delete Audio is successful`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return rejectWithValue(error);
    }
  },
);

export default {
  getAllUsers,
  updateSubscription,
  delateUser,
  getAllCourse,
  createCourse,
  updateCourseInUser,
  deleteCourse,
  updateCourse,
  getAllLessons,
  createLesson,
  updateLesson,
  deleteLesson,
  getAllModules,
  createModules,
  updateModules,
  deleteModules,
  updateCourseTariff,
  updateCourseActive,
  updateCoursePublish,
  getAudios,
  createAudios,
  deleteAudios,
};
