import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getOptions = createAsyncThunk(
  'admin/getOptions',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('options');
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default {
  getOptions,
};
