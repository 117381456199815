import { useState, Fragment } from 'react';
import { MenuButton } from '@root/components';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

export const TableMenu = ({ menuButtonSx = {}, menuConf, row }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (<>
    <MenuButton
      sx={menuButtonSx}
      open={open}
      handleClick={handleClick}
    >
      <MoreVertIcon />
    </MenuButton>

    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {menuConf.map(menuData => {
        const status = menuData.key === 'publish' ? row.is_publish : row.active
        return (
          <Fragment key={menuData.key}>
            <MenuItem onClick={() => {
              handleClose()

              if (menuData?.onClickHandel) {
                console.log(row._id);
                menuData?.onClickHandel(row._id, menuData.key)
              }
            }}>
              {menuData.label(status)}
            </MenuItem>
          </Fragment>
        )
      })}

    </Menu>
  </>
  )
}