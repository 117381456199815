import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import authSelectors from '@redux/auth/auth-selectors';
import PropTypes from 'prop-types';

export function PublicRoute({ element }) {
  const token = useSelector(authSelectors.getUserToken);
  return <>{token ? <Navigate to={'/'} /> : element}</>;
}

PublicRoute.propTypes = {
  element: PropTypes.node,
};
