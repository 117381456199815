import React from 'react';
import styled from 'styled-components';

import { ProfileNavigation, ProfileDetails } from '@root/modules';

export const Profile = () => {
  return (
    <Main>
      <h1>Профіль</h1>
      <Wrapper>
        <ProfileNavigation />
        <ProfileDetails />
      </Wrapper>
    </Main>
  );
};

const Main = styled.main`
  margin: 30px;
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  @media screen and (max-width: 830px) {
    flex-direction: column;
  }
`;
