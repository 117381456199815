import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import operation from '@redux/admin/admin-operation';
import adminSelectors from '@redux/admin/admin-selectors';
import { DenseTable, FormModule } from '@root/components';
import { moduleConf } from '@root/conf/tableConf';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import optionsSelectors from '@redux/options/options-selectors';
import operationOptions from '@redux/options/options-operation';

export const ModuleTabs = () => {
  const [isModal, setIsModal] = useState(false);
  const [chooseId, setChooseId] = useState(null);
  const module = useSelector(adminSelectors.getModule);
  const options = useSelector(optionsSelectors.getOptions);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(operation.getAllModules());
    dispatch(operationOptions.getOptions());
  }, [dispatch]);

  const openModal = () => {
    setIsModal(true);
  };

  const closeModal = () => {
    setIsModal(false);
    setChooseId(null);
  };

  const onClickHandel = id => {
    setChooseId([...module].find(el => el._id === id));
    openModal();
  };

  const menuConf = [
    {
      label: () => 'Edit',
      key: 'base',
      type: 'modal',
      onClickHandel: onClickHandel
    },

  ];

  return (
    <>
      <TableWrapper>
        <DenseTable
          conf={moduleConf}
          data={module}
          onClickHandel={onClickHandel}
          menuConf={menuConf}
        />
      </TableWrapper>
      <IconWrapper>
        <IconButton aria-label="add" onClick={openModal}>
          <AddCircleOutlineOutlinedIcon color="primary" fontSize="large" />
        </IconButton>
      </IconWrapper>

      {isModal && <FormModule handleClose={closeModal} data={chooseId} options={options} />}
    </>
  );
}

const TableWrapper = styled.div`
  margin: 50px 0;
`;

const IconWrapper = styled.div`
  position: fixed;
  bottom: 40px;
  right: 20px;
`;
