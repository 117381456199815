import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

const getCourse = createAsyncThunk(
  'home/getCourse',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('course');
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const getCourseById = createAsyncThunk(
  'home/getCourseById',
  async ({ courseId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`course/${courseId}`);
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const getLessonsByID = createAsyncThunk(
  'home/getLessonsByID',
  async ({ lessonId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`lesson/${lessonId}`);
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

export default {
  getCourse,
  getCourseById,
  getLessonsByID,
};
