import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import operation from '@redux/admin/admin-operation';
import adminSelectors from '@redux/admin/admin-selectors';
import { courseConf } from '@root/conf/tableConf';
import { DenseTable, FormCourse, FormTariff } from '@root/components';
import styled from 'styled-components';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import optionsSelectors from '@redux/options/options-selectors';
import operationOptions from '@redux/options/options-operation';

export const CourseTabs = () => {
  const [isModal, setIsModal] = useState(false);
  const [typeOfModal, setTypeOfModal] = useState(null);
  const [chooseId, setChooseId] = useState(null);
  const course = useSelector(adminSelectors.getCourse);
  const options = useSelector(optionsSelectors.getOptions);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(operation.getAllCourse());
    dispatch(operationOptions.getOptions());
  }, [dispatch]);

  const openModal = () => {
    setIsModal(true);
    setTypeOfModal('base');
  };

  const closeModal = () => {
    setIsModal(false);
    setChooseId(null);
    setTypeOfModal(null);
  };

  const onClickHandel = (id, key) => {
    setChooseId([...course].find(el => el._id === id));
    openModal();
    setTypeOfModal(key);
  };

  const menuConf = [
    {
      label: () => 'Edit',
      key: 'base',
      type: 'modal',
      onClickHandel: onClickHandel
    },
    {
      label: (status) => status ? 'UnPublish' : 'Publish',
      key: 'publish',
      type: 'action',
      onClickHandel: (id) => {
        const current = course.find(el => el?._id === id)

        dispatch(operation.updateCoursePublish({ payload: { is_publish: !current.is_publish }, courseId: id }));
      }
    },
    {
      label: (status) => status ? 'Inactive' : 'Active',
      key: 'active',
      type: 'action',
      onClickHandel: (id) => {
        const current = course.find(el => el?._id === id)
        dispatch(operation.updateCourseActive({ payload: { active: !current?.active }, courseId: id }));
      }
    },

    {
      label: () => 'Change a Tariff',
      key: 'tariff',
      type: 'modal',
      onClickHandel: onClickHandel
    },
  ];

  const getModal = () => {
    switch (typeOfModal) {
      case 'base':
        return (
          <FormCourse
            handleClose={closeModal}
            initData={chooseId}
            options={options}
          />
        );

      case 'tariff':
        return (
          <FormTariff
            handleClose={closeModal}
            initData={chooseId}
          />
        );

      default:
        break;
    }
  };
  return (
    <>
      <TableWrapper>
        <DenseTable
          conf={courseConf}
          data={course}
          menuConf={menuConf}
        />
      </TableWrapper>
      <IconWrapper>
        <IconButton aria-label="add" onClick={openModal}>
          <AddCircleOutlineOutlinedIcon color="primary" fontSize="large" />
        </IconButton>
      </IconWrapper>

      {isModal && typeOfModal && getModal()}

    </>
  );
};

const TableWrapper = styled.div`
  margin: 50px 0;
`;

const IconWrapper = styled.div`
  position: fixed;
  bottom: 40px;
  right: 20px;
`;
