import styled from 'styled-components';

export const AuthModal = ({ text, children }) => {
  return (
    <ModalLogin>
      <Title>{text}</Title>
      {children}
    </ModalLogin>
  );
};

const ModalLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background: white;
  width: 400px;
  margin: auto;
  border-radius: 20px;
`;
const Title = styled.strong`
  display: block;
  font-size: 18px;
  line-height: 1;
`;
