import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import authSelectors from '@redux/auth/auth-selectors';
import { useDispatch, useSelector } from 'react-redux';
import { onClearValidationRememberToken } from '@redux/auth/auth-slice';
import { validPassword } from '@root/helpers/validation';
import { toast } from 'react-toastify';

const RefreshPassword = () => {
  const [data, setData] = useState({
    newPassword: '',
    repeatPassword: '',
  });

  const [error, setError] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const rememberToken = useSelector(authSelectors.getValidationRememberToken);
  const navigation = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!rememberToken) {
      navigation('/login');
    }
  }, []);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const validation = () => {
    let flag = true;
    let localError = {};
    const { newPassword, repeatPassword } = data;

    Object.keys(data).forEach(key => {
      if (!data[key] || data[key] === '') {
        localError = {
          ...localError,
          [key]: { init: true, text: "Обов'язкове поле" },
        };
        flag = false;
      }
    });

    if (newPassword !== '' && validPassword(newPassword)) {
      localError = {
        ...localError,
        newPassword: { init: true, text: 'Некоректний password' },
      };
      flag = false;
    }

    if (repeatPassword !== '' && repeatPassword !== newPassword) {
      localError = {
        ...localError,
        repeatPassword: { init: true, text: 'Ваш пароль не співпадає' },
      };
      flag = false;
    }

    setError(localError);
    return flag;
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (validation()) {
      try {
        const response = await axios.post('/auth/new-password-by-token', {
          validation_remember_token: rememberToken,
          newPassword,
        });
        setShowSuccess(true);
        dispatch(onClearValidationRememberToken());
      } catch (error) {
        toast.error(`Помилка при відновленні пароля`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  const { newPassword, repeatPassword } = data;
  return (
    <>
      <LoginWrap>
        {showSuccess ? (
          <Container>
            <Message>Пароль успішно відновлений!</Message>
            <CheckCircleOutlineIcon sx={{ fontSize: 200 }} color="success" />
            <ButtonWrapp>
              <Link to={'/login'}>
                <Button type="submit" variant="outlined">
                  Логін
                </Button>
              </Link>
            </ButtonWrapp>
          </Container>
        ) : (
          <ModalLogin>
            <Title>Відновлення пароля</Title>
            <Form onSubmit={handleSubmit}>
              <TextField
                type="password"
                label="New password"
                variant="outlined"
                name="newPassword"
                value={newPassword}
                onChange={handleInputChange}
                size="small"
                margin="dense"
                autoComplete="off"
                sx={{ width: '100%' }}
                error={error.newPassword && error.newPassword.init}
                helperText={error.newPassword && error.newPassword.text}
              />
              <TextField
                type="password"
                label="Repeat password"
                variant="outlined"
                name="repeatPassword"
                value={repeatPassword}
                onChange={handleInputChange}
                size="small"
                margin="dense"
                autoComplete="off"
                sx={{ width: '100%' }}
                error={error.repeatPassword && error.repeatPassword.init}
                helperText={error.repeatPassword && error.repeatPassword.text}
              />
              <ButtonWrapper>
                <Button type="submit" variant="outlined">
                  Submit
                </Button>
              </ButtonWrapper>
            </Form>
          </ModalLogin>
        )}
      </LoginWrap>
    </>
  );
};

export default RefreshPassword;

const Title = styled.strong`
  display: block;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 30px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 100%;

  button {
    margin-top: 10px;
  }
`;

const LoginWrap = styled.div`
  display: flex;
  background-color: grey;
  height: 100vh;
  width: 100%;
`;

const ModalLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background: white;
  width: 600px;
  min-height: 300px;
  margin: auto;
  border-radius: 20px;
`;

const Message = styled.p`
  text-align: center;
  font-size: 16px;
  margin-bottom: 40px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background: white;
  width: 460px;
  margin: auto;
  border-radius: 20px;
`;

const ButtonWrapp = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  width: 100%;
`;
