import validator from 'validator';

export const validEmail = value => {
  if (
    validator.isEmail(value, {
      allow_display_name: true,
      blacklisted_chars: 'а-яА-ЯёЁїЇіІєЄґҐ',
    })
  ) {
    return false;
  } else {
    return true;
  }
};

export const validPassword = value => {
  if (value.length < 6) {
    return true;
  } else {
    return false;
  }
};
