import { useState } from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import operation from '@redux/auth/auth-operation';
import authSelectors from '@redux/auth/auth-selectors';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { validEmail } from '@root/helpers/validation';
import { ModalBasic } from '@root/components';

export const SignUp = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const isRegister = useSelector(authSelectors.getIsRegister);

  const onInputChange = e => {
    switch (e.target.name) {
      case 'name':
        setName(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'password':
        setPassword(e.target.value);
        break;

      default:
        break;
    }
  };

  const validation = () => {
    if (!name || name === '') {
      setError({ name: { init: true, text: "Обов'язкове поле" } });
      return true;
    }
    if (!email || email === '') {
      setError({ email: { init: true, text: "Обов'язкове поле" } });
      return true;
    }
    if (validEmail(email)) {
      setError({ email: { init: true, text: 'Некоректний email' } });
      return true;
    }
    if (!password || password === '') {
      setError({ password: { init: true, text: "Обов'язкове поле" } });
      return true;
    }
    if (password.length < 6) {
      setError({
        password: {
          init: true,
          text: 'Мінімальна довжина пароля 6 символів',
        },
      });
      return true;
    }
    setError({});
    return false;
  };

  const handelSubmit = e => {
    e.preventDefault();
    const credentials = { name, email, password };
    if (!validation()) dispatch(operation.signUp({ credentials, navigate }));
  };

  const handelInfoClose = () => {
    dispatch(operation.isInfoClose({ isRegister: !isRegister, navigate }));
  };

  return (
    <LoginWrap>
      <ModalLogin>
        <Title>Вітаємо в Portuguese Vibe</Title>
        <Form onSubmit={handelSubmit}>
          <TextField
            error={error?.name?.init}
            helperText={error?.name?.text}
            id="outlined-basic"
            label="Ім'я"
            variant="outlined"
            name="name"
            value={name}
            onInput={onInputChange}
            size="small"
            margin="dense"
            autoComplete="off"
          />
          <TextField
            error={error?.email?.init}
            helperText={error?.email?.text}
            id="outlined-basic"
            label="Email"
            variant="outlined"
            name="email"
            value={email}
            onInput={onInputChange}
            size="small"
            margin="dense"
            autoComplete="off"
          />

          <TextField
            error={error?.password?.init}
            helperText={error?.password?.text}
            id="outlined-basic"
            label="Пароль"
            variant="outlined"
            type="password"
            name="password"
            value={password}
            onInput={onInputChange}
            size="small"
            margin="dense"
            autoComplete="off"
          />
          <Button type="submit" variant="outlined">
            Зареєструватись
          </Button>
        </Form>

        <Backto>
          Повернутись до{' '}
          <Link to={'/login'} style={{ color: '#1976d2' }}>
            Логіну
          </Link>
        </Backto>
        {isRegister && (
          <ModalBasic handleClose={handelInfoClose}>
            <ColumnWrap>
              Ваш акаунт був зареєстрований. На вашу електронну адресу був
              надісланий лист-підтверження верифікації. Якщо ви не отримали
              листа, перевірте будь ласка, спам або ж зверніться до
              адміністратора!
              <Button
                variant="outlined"
                onClick={handelInfoClose}
                sx={{ marginTop: '10px' }}
              >
                Ok
              </Button>
            </ColumnWrap>
          </ModalBasic>
        )}
      </ModalLogin>
    </LoginWrap>
  );
};

const LoginWrap = styled.div`
  display: flex;
  background-color: grey;
  height: 100vh;
  width: 100vw;
`;

const ModalLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background: white;
  width: 400px;
  margin: auto;
  border-radius: 20px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 70%;

  button {
    margin-top: 10px;
  }
`;

const Title = styled.strong`
  display: block;
  font-size: 18px;
  line-height: 1;
`;

const Backto = styled.p`
  display: block;
  font-size: 12px;
  line-height: 1;

  margin-top: 25px;
`;

const ColumnWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
