import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { profileConf } from '@root/conf/profileConf';

export const ProfileNavigation = () => {
  const activeLink = 'profile__link profile__link--active';
  const normalLink = 'profile__link ';

  return (
    <Wrapper>
      <List>
        {profileConf.map(({ name, path }, idx) => (
          <ListItem sx={{ p: 0, mt: idx !== 0 ? '5px' : 0 }}>
            <NavLink
              to={path}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              {name}
            </NavLink>
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;

  @media screen and (min-width: 830px) {
    border-bottom: none;

    width: 25%;
  }

  .profile__link {
    /* стили для неактивных ссылок */
    color: #000;
    border-bottom: 1px solid #000;
    text-decoration: none;
    padding: 5px 10px;
    width: 100%;
    text-align: center;
  }

  .profile__link--active {
    /* стили для активной ссылки */
    font-weight: bold;
    color: #de5736;
    border-bottom: 1px solid #de5736;
  }
`;
