import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

const getUserInfo = createAsyncThunk(
  'user/getUsersInfo',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('/user/user-info');

      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const changeUserInfo = createAsyncThunk(
  'user/changeUserInfo',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put('/user/user-info', payload);

      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

export default { getUserInfo, changeUserInfo };
