import { useEffect, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Spinner } from './components';
import {
  Course,
  LessonList,
  Admin,
  Login,
  SignUp,
  Games,
  Contact,
  VerifyEmailPage,
  Redirect,
  LessonPage,
  AudioPage,
  Profile,
} from '@root/page';
import { Home } from '@root/templates';
import { PrivateRoute, PublicRoute } from '@root/middleware';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import authSelectors from '@redux/auth/auth-selectors';
import operation from '@redux/auth/auth-operation';
import ForgotPassword from './page/ForgotPassword/index';
import RefreshPassword from './page/RefreshPassword/RefreshPassword';

export function App() {
  const isRefreshing = useSelector(authSelectors.getIsRefreshing);
  const role = useSelector(authSelectors.getRole);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(operation.refreshCurrentUser());
  }, []);

  return (
    <div className="App">
      <Suspense fallback={<Spinner />}>
        <ToastContainer />
        {!isRefreshing && (
          <Routes>
            <Route path="/" element={<PrivateRoute element={<Home />} />}>
              <Route index element={<PrivateRoute element={<Course />} />} />
              <Route
                path="/:courseId"
                element={<PrivateRoute element={<LessonList />} />}
              />
              <Route
                path="/audio"
                element={<PrivateRoute element={<AudioPage />} />}
              />
              <Route
                path="/:courseId/:lessonId/:tab?"
                element={<PrivateRoute element={<LessonPage />} />}
              />
              <Route
                path="/games/:gameType"
                element={<PrivateRoute element={<Games />} />}
              />
              <Route
                path="/contacts"
                element={<PrivateRoute element={<Contact />} />}
              />

              <Route
                path="/profile/:tab"
                element={<PrivateRoute element={<Profile />} />}
              />
              {role === 'admin' && (
                <Route
                  path="admin/:tab"
                  element={<PrivateRoute element={<Admin />} />}
                />
              )}
            </Route>

            <Route
              path="/auth/verify-email/:token"
              element={<PublicRoute element={<VerifyEmailPage />} />}
            />

            <Route
              path="/login"
              element={<PublicRoute element={<Login />} />}
            />
            <Route
              path="/forgot-password"
              element={<PublicRoute element={<ForgotPassword />} />}
            />
            <Route
              path="/refresh-password"
              element={<PublicRoute element={<RefreshPassword />} />}
            />

            <Route
              path="/sign_up"
              element={<PublicRoute element={<SignUp />} />}
            />
            <Route path="*" element={<Redirect />} />
          </Routes>
        )}
      </Suspense>
    </div>
  );
}
