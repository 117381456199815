import { useEffect } from 'react';
import { AppBar } from '@mui/material';
import { ModulesDetails } from '@root/modules';
import { useSelector, useDispatch } from 'react-redux';
import homeSelectors from '@redux/home/home-selectors';
import operation from '@redux/home/home-operation';
import { useParams, useNavigate } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styled from 'styled-components';

export const LessonList = () => {
  const modules = useSelector(homeSelectors.getModules);
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(operation.getCourseById({ courseId }));
  }, []);

  return (
    <>
      <AppBar
        sx={{
          position: 'relative',
          backgroundColor: '#9e9e9ed1',
          p: '15px 5px',
        }}
      >
        <GoBackBtn type="button" onClick={() => navigate(`/`)}>
          <ArrowBackIcon sx={{ color: '#ffffff' }} />
          <Text>Назад</Text>
        </GoBackBtn>

      </AppBar>
      <ModulesDetails modules={modules} />
    </>
  );
};

const GoBackBtn = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Text = styled.p`
  font-size: 20px;
  margin-left: 5px;
  color: #fff;
`;
