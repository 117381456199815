import * as React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import styled from 'styled-components';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutRoundedIcon from '@mui/icons-material/ZoomOutRounded';
import ZoomInMapRoundedIcon from '@mui/icons-material/ZoomInMapRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

export const PdfViewer = ({ fileUrl }) => {
  const fullScreenPluginInstance = fullScreenPlugin();
  const { EnterFullScreen } = fullScreenPluginInstance;

  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;
  const pageLayout = {
    transformSize: ({ size }) => ({
      height: size.height + 30,
      width: size.width + 30,
    }),
  };
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <PdfWrapper>
        <div
          style={{
            alignItems: 'center',
            backgroundColor: '#eeeeee',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            display: 'flex',
            padding: '4px',
          }}
        >
          <Toolbar>
            {props => {
              const {
                CurrentPageInput,
                CurrentScale,
                GoToNextPage,
                GoToPreviousPage,
                NumberOfPages,
                ZoomIn,
                ZoomOut,
              } = props;
              return (
                <>
                  <div style={{ padding: '0px 2px' }}>
                    <ZoomOut>
                      {props => (
                        <button
                          style={{
                            backgroundColor: '#357edd',
                            border: 'none',
                            borderRadius: '4px',
                            color: '#ffffff',
                            cursor: 'pointer',
                            padding: '4px',
                          }}
                          onClick={props.onClick}
                        >
                          <ZoomOutRoundedIcon />
                        </button>
                      )}
                    </ZoomOut>
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <CurrentScale>
                      {props => (
                        <span>{`${Math.round(props.scale * 100)}%`}</span>
                      )}
                    </CurrentScale>
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <ZoomIn>
                      {props => (
                        <button
                          style={{
                            backgroundColor: '#357edd',
                            border: 'none',
                            borderRadius: '4px',
                            color: '#ffffff',
                            cursor: 'pointer',
                            padding: '4px',
                          }}
                          onClick={props.onClick}
                        >
                          <ZoomInRoundedIcon />
                        </button>
                      )}
                    </ZoomIn>
                  </div>
                  <div
                    style={{
                      alignItems: 'center',
                      backgroundColor: '#eeeeee',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                      display: 'flex',
                      padding: '4px',
                    }}
                  >
                    <EnterFullScreen>
                      {props => (
                        <button
                          style={{
                            backgroundColor: '#357edd',
                            border: 'none',
                            borderRadius: '4px',
                            color: '#ffffff',
                            cursor: 'pointer',
                            padding: '4px',
                          }}
                          onClick={props.onClick}
                        >
                          <ZoomInMapRoundedIcon />
                        </button>
                      )}
                    </EnterFullScreen>
                  </div>

                  <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                    <GoToPreviousPage>
                      {props => (
                        <button
                          style={{
                            backgroundColor: props.isDisabled
                              ? '#96ccff'
                              : '#357edd',
                            border: 'none',
                            borderRadius: '4px',
                            color: '#ffffff',
                            cursor: props.isDisabled
                              ? 'not-allowed'
                              : 'pointer',
                            padding: '4px',
                          }}
                          disabled={props.isDisabled}
                          onClick={props.onClick}
                        >
                          <ArrowBackIosRoundedIcon />
                        </button>
                      )}
                    </GoToPreviousPage>
                  </div>
                  <div style={{ padding: '0px 2px', width: '4rem' }}>
                    <CurrentPageInput />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    / <NumberOfPages />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <GoToNextPage>
                      {props => (
                        <button
                          style={{
                            backgroundColor: props.isDisabled
                              ? '#96ccff'
                              : '#357edd',
                            border: 'none',
                            borderRadius: '4px',
                            color: '#ffffff',
                            cursor: props.isDisabled
                              ? 'not-allowed'
                              : 'pointer',
                            padding: '4px',
                          }}
                          disabled={props.isDisabled}
                          onClick={props.onClick}
                        >
                          <ArrowForwardIosRoundedIcon />
                        </button>
                      )}
                    </GoToNextPage>
                  </div>
                </>
              );
            }}
          </Toolbar>
        </div>

        <ViewerWrapper>
          <Viewer
            fileUrl={fileUrl}
            plugins={[toolbarPluginInstance, fullScreenPluginInstance]}
            pageLayout={pageLayout}
          />
        </ViewerWrapper>
      </PdfWrapper>
    </Worker>
  );
};

const PdfWrapper = styled.div`
  margin: 0 auto;
  width: 90vw;
  height: 78vh;
  border: '1px solid rgba(0, 0, 0, 0.3)';
  display: 'flex';
  flex-direction: 'column';
`;

const ViewerWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
