import { useSearchParams } from 'react-router-dom';
import { DefaultPlayer as VideoCustom } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import styled from 'styled-components';

const { REACT_APP_HOST } = process.env

export const AudioPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <VideoWrapper>
        <VideoCustom
          src={REACT_APP_HOST + "/" + searchParams.get('url')}
          loop
          muted
          controls={['PlayPause', 'Time', 'Volume', 'Fullscreen']}
          onCanPlayThrough={() => {
          }}
        />

      </VideoWrapper>
    </>
  );
};

const VideoWrapper = styled.div`
  margin: 0 auto 25px auto;
  &,
  video {
    width: 80vw;
    max-height: 800px;
  }
`;
