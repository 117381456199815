import { useState, Fragment } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';
import { MultipleSelectCheckmarks } from '@root/components';
import { BasicSelect } from '../Select';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import operation from '@redux/admin/admin-operation';
import { TableMenu } from './TableMenu'

const TYPE_PROGRAM = [
  { name: 'starter', _id: 'starter' },
  { name: 'premium', _id: 'premium' },
  { name: 'vip', _id: 'vip' },
];

export const DenseTable = ({ conf, data, options, menuConf }) => {

  const dispatch = useDispatch();


  const getAction = (el, row) => {
    console.log(el);
    switch (el.key) {
      case 'users':
        return (
          <TableCell
            component="th"
            scope="row"
            key={'action' + row._id}
          >
            <Button
              type="submit"
              color="error"
              variant="outlined"
              onClick={() => {
                dispatch(operation.delateUser({ userId: row._id }));
              }}
            >
              Delete Student
            </Button>
          </TableCell>
        );

      case 'course':
        return (
          <TableCell
            component="th"
            scope="row"
            key={'action' + row._id}
          >
            <TableMenu menuConf={menuConf} row={row} />
          </TableCell >
        );

      default:
        break;
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650, borderTop: '1px solid rgba(224, 224, 224, 1)' }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            {conf.map(el => (
              <TableCell key={el.label}>{el.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
              key={row._id}
            >
              {conf.map(el => {
                switch (el.value) {
                  case 'course':
                    return (
                      <TableCell
                        component="th"
                        scope="row"
                        key={'Course' + row._id}
                      >
                        <MultipleSelectCheckmarks
                          name={'Course'}
                          data={options.course}
                          currentId={row[el.value]}
                          fetchValue={id =>
                            dispatch(
                              operation.updateCourseInUser({
                                userId: row._id,
                                courseId: id,
                              }),
                            )
                          }
                          sx={{ mt: '10px' }}
                        />
                      </TableCell>
                    );

                  case 'subscription':
                    return (
                      <TableCell
                        component="th"
                        scope="row"
                        key={'Subscription' + row._id}
                      >
                        <BasicSelect
                          data={TYPE_PROGRAM}
                          currentId={row[el.value]}
                          name={'Subscription'}
                          fetchValue={id =>
                            dispatch(
                              operation.updateSubscription({
                                userId: row._id,
                                subscription: id,
                              }),
                            )
                          }
                        />
                      </TableCell>
                    );

                  case 'isVerify':
                    return (
                      <TableCell
                        component="th"
                        scope="row"
                        key={'isVerify' + row._id}
                      >
                        <VerifyLamp color={row[el.value] ? 'green' : 'red'} />
                      </TableCell>
                    );

                  case 'action':
                    if (row?.role === 'admin') return '';


                    return getAction(el, row)
                  default:
                    return (
                      <TableCell
                        component="th"
                        scope="row"
                        key={row[el.value] + el.value}
                      >
                        {row[el.value]}
                      </TableCell>
                    );
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const VerifyLamp = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${props => props.color};
`;
