import {
  ModalBasic,
  Logic,
} from '@root/components';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import operations from '@root/redux/admin/admin-operation';
import 'react-quill/dist/quill.snow.css';
import { MultiList } from '@root/components/MultiList/MultiList';
import Button from '@mui/material/Button';

export const FormTariff = ({ handleClose, initData }) => {
  const [data, setData] = useState({
    basePrice: '',
    premPrice: '',
    vipPrice: '',
    baseCondition: [],
    premiumCondition: [],
    vipCondition: []
  });
  const [error, setError] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setData(initData?.tariffs)
  }, [initData])


  const validation = () => {
    const { basePrice, premPrice, vipPrice } = data;
    const state = {}
    let flag = false

    Object.keys({
      basePrice,
      premPrice,
      vipPrice,
    }).forEach(key => {
      if (!data[key] || data[key] === '') {
        state[key] = { init: true, text: 'Required' }
        flag = true;
      }
    });

    setError(state);
    return flag;
  };

  const handelSubmit = (e) => {
    e.preventDefault()
    if (validation()) return

    dispatch(operations.updateCourseTariff({
      courseId: initData._id, payload: {
        tariffs: data
      },
    })).then(() => {
      handleClose()
    })
  }

  const onInputChange = e => {

    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onChangeIterableList = (name) => (value, idx) => {
    const copy = [...data[name]]
    copy[idx] = value
    setData({ ...data, [name]: copy })

  }
  const onDeleteItemFromIterableList = (name) => (idx) => {
    const copy = [...data[name]]

    if (idx === 0) {
      copy.shift()
    } else {
      copy.splice(idx, idx)
    }

    setData({ ...data, [name]: copy })
  }

  const {
    basePrice,
    premPrice,
    vipPrice,
    baseCondition = [],
    premiumCondition = [],
    vipCondition = []
  } = data

  return (
    <>
      <ModalBasic handleClose={handleClose}>
        <Form onSubmit={handelSubmit}>
          <Title>{initData ? 'Edit' : 'Create'} the Tariff</Title>
          <FormContext>
            <WrapperForm>
              <Card>
                <Title>Standard</Title>
                <Wrapper>
                  <MultiList
                    value={baseCondition}
                    onChange={onChangeIterableList('baseCondition')}
                    onDelete={onDeleteItemFromIterableList('baseCondition')}
                    label={"Base Condition"}
                  />
                </Wrapper>
                <Price>
                  <TextField
                    error={error?.basePrice?.init}
                    helperText={error?.basePrice?.text}
                    id="outlined-basic"
                    label="Price"
                    variant="outlined"
                    name="basePrice"
                    value={basePrice}
                    onInput={onInputChange}
                    size="small"
                    margin="dense"
                    sx={{ width: '100%' }}
                  />
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    name="vipPrice"
                    label="Currency"
                    value={'EUR'}
                    size="small"
                    margin="dense"
                    sx={{ width: '150px' }}
                    disabled
                  />
                </Price>
              </Card>
              <Card>
                <Title>Premium</Title>
                <Wrapper>
                  <MultiList
                    value={premiumCondition}
                    onChange={onChangeIterableList('premiumCondition')}
                    onDelete={onDeleteItemFromIterableList('premiumCondition')}
                    label={"Premium Condition"}
                  />
                </Wrapper>
                <Price>
                  <TextField
                    error={error?.premPrice?.init}
                    helperText={error?.premPrice?.text}
                    id="outlined-basic"
                    label="Price"
                    variant="outlined"
                    name="premPrice"
                    value={premPrice}
                    onInput={onInputChange}
                    size="small"
                    margin="dense"
                    sx={{ width: '100%' }}
                  />
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    name="vipPrice"
                    label="Currency"
                    value={'EUR'}
                    size="small"
                    margin="dense"
                    sx={{ width: '150px' }}
                    disabled
                  />
                </Price>
              </Card>
              <Card>
                <Title>VIP</Title>
                <Wrapper>
                  <MultiList
                    value={vipCondition}
                    onChange={onChangeIterableList('vipCondition')}
                    onDelete={onDeleteItemFromIterableList('vipCondition')}
                    label={"Vip Condition"}
                  />
                </Wrapper>
                <Price>
                  <TextField
                    error={error?.vipPrice?.init}
                    helperText={error?.vipPrice?.text}
                    id="outlined-basic"
                    label="Price"
                    variant="outlined"
                    name="vipPrice"
                    value={vipPrice}
                    onInput={onInputChange}
                    size="small"
                    margin="dense"
                    sx={{ width: '100%' }}
                  />
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    name="vipPrice"
                    label="Currency"
                    value={'EUR'}
                    size="small"
                    margin="dense"
                    sx={{ width: '150px' }}
                    disabled
                  />
                </Price>

              </Card>

            </WrapperForm>
          </FormContext>
          <FlexContainer>
            <Button type="submit" variant="outlined">
              Update
            </Button>
          </FlexContainer>

        </Form>

      </ModalBasic>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  margin-top: 10px;

  flex: 1 1 0;

  .ql-container {
    min-height: 70px;
    max-height: 70px
   }
`;

const FormContext = styled.div`
  margin: 20px -20px 20px 0; 
   overflow: hidden;
`
const WrapperForm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  margin-right: 20px;
  height: 100%;
`

const Title = styled.strong`
  margin-bottom: 10px;
  display: block;
  font-size: 18px;
  line-height: 1;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  min-height: 300px;
  width: 300px;
  border: 1px solid black;
  border-radius: 4px;

  padding: 10px;
  height: 400px;

  overflow: hidden auto;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c5c8d2;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
    border-left: 1px solid #dbdee9;
  }

`

const Price = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  justify-content: space-between;

`
const Wrapper = styled.div`
  margin: 10px;
  width: 100%;
`;

const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;