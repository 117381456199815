import { UserInfo } from './UserInfo';
import { PasswordChange } from './PasswordChange';
import { useParams } from 'react-router';

export const ProfileDetails = () => {
  const { tab } = useParams();

  const renderProfileTab = () => {
    switch (tab) {
      case 'user-info':
        return <UserInfo />;
      case 'password-change':
        return <PasswordChange />;
      // якщо потрібно сюди про оплату, модуль не створено!
      // case 'payment':
      //   return <PaymentDetails/>
      default:
        return null;
    }
  };

  return <>{renderProfileTab()}</>;
};
