import { useState, useRef } from 'react';
import styled from 'styled-components';
import { Typography, FormHelperText, IconButton } from '@mui/material';
import { Cloud } from 'assets';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';

export const FileLoader = ({ file, setFile, title, error, setUpdateFile }) => {
  const [dragActive, setDragActive] = useState(false);
  const fileRef = useRef();

  const handelClick = e => {
    fileRef.current.click();
  };

  // handle drag events
  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      setFile(file);
    }
  };

  const handleFile = e => {
    const file = e.currentTarget.files[0];
    setFile(file);
  };

  const handleEdit = () => {
    fileRef.current.click();
    if (file?.id) setUpdateFile(file?.id);
  };

  const handleDeleted = () => {
    setFile('');
    fileRef.current.value = '';
  };

  return (
    <Component>
      {file ? (
        <AvatarWrapper>
          <Typography
            sx={{ mt: '4px', color: '#526484', fontSize: 12, p: '5px' }}
            component={'p'}
          >
            {file.name}
          </Typography>
          <ActionWrapper>
            <IconButton aria-label="delete" size="small" onClick={handleEdit}>
              <FileUploadRoundedIcon />
            </IconButton>
            {/* <IconButton
              aria-label="delete"
              size="small"
              onClick={handleDeleted}
            >
              <DeleteIcon />
            </IconButton> */}
          </ActionWrapper>
        </AvatarWrapper>
      ) : (
        <>
          {' '}
          <DropArea onClick={handelClick} onDragEnter={handleDrag}>
            <CloudIcon src={Cloud} alt="Imag Cloud" />
            <Typography
              sx={{ mt: '10px', color: '#364A63', fontSize: 12 }}
              align={'center'}
              component={'p'}
            >
              {title}
            </Typography>
            <Typography
              sx={{ mt: '4px', color: '#526484', fontSize: 12 }}
              align={'center'}
              component={'p'}
            >
              Натисніть, щоб завантажити або перетяніть файл
            </Typography>

            {dragActive && (
              <HideBox
                id="drag-file-element"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></HideBox>
            )}
          </DropArea>
        </>
      )}
      <File type="file" ref={fileRef} onChange={handleFile} />
      {error?.init && (
        <FormHelperText
          error={true}
          id="component-error-text"
          sx={{ display: 'flex', ml: '10px', mr: 'auto' }}
        >
          {error?.text}
        </FormHelperText>
      )}
    </Component>
  );
};

const Component = styled.div`
  width: 100%;
`;

const AvatarWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-left: 20px;
`;

const DropArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8px;

  border: 1px dashed #c5c8d2;
  border-radius: 4px;
  padding: 16px;

  cursor: pointer;
`;

const CloudIcon = styled.img`
  margin-left: auto;
  margin-right: auto;
`;

const File = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
`;

const HideBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
