import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Header, Navigation } from '@root/modules';
import { useParams } from 'react-router';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const Home = () => {
  const [open, setOpen] = useState(false);
  const { lessonId } = useParams();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header open={open} setOpen={setOpen} />
      {!lessonId && <Navigation setOpen={setOpen} open={open} />}

      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
};
