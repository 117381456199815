import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userOperation from '@redux/user/user-operation';
import userSelectors from '@redux/user/user-selectors';
import { validEmail } from '@root/helpers/validation';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import AvatarUpload from '@root/components/Avatars/ImageAvatars/index';

export const UserInfo = () => {
  const [error, setError] = useState({});

  const [data, setData] = useState({
    name: '',
    email: '',
    instagramLink: '',
    city: '',
    consent: false,
    file: null,
    avatar: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userOperation.getUserInfo());
  }, []);

  // Приклад використання:
  const userInfo = useSelector(userSelectors.getUserInfo);

  // Заповнення полів форми при отриманні даних про користувача
  useEffect(() => {
    if (userInfo) {
      setData({
        name: userInfo.name || '',
        email: userInfo.email || '',
        instagramLink: userInfo.instagramLink || '',
        city: userInfo.city || '',
        consent: userInfo.consent || false,
        avatar: userInfo.avatar || '',
      });
    }
  }, [userInfo]);

  const onInputChange = e => {
    // Обробка змін у введеннях
    const { name, value } = e.target;
    switch (e.target.name) {
      case 'avatar':
        const selectedFile = e.target.files[0];
        setData({ ...data, file: selectedFile });
        break;
      default:
        setData({ ...data, [name]: value });
        break;
    }
  };

  // Перевірка полів форми при отриманні даних про користувача
  const validation = () => {
    let flag = false;
    let localError = {};
    const { name, email, instagramLink, city, file } = data;

    if (!name || name === '') {
      localError = {
        ...localError,
        name: { init: true, text: "Обов'язкове поле" },
      };
      flag = true;
    }
    if (!email || email === '') {
      localError = {
        ...localError,
        email: { init: true, text: "Обов'язкове поле" },
      };
      flag = true;
    }

    if (email && validEmail(email)) {
      localError = {
        ...localError,
        email: { init: true, text: 'Некоректний email' },
      };
      flag = true;
    }

    // Преревірка ваги аватара
    if (!avatar && file.size > 4 * 1024 * 1024) {
      localError = {
        avatarTitle: {
          init: true,
          text: 'Максимальна вага зображення 4МБ',
        },
      };
      flag = true;
    }
    // Валідація на min та max довжину
    const validateLength = (value, fieldName, minLength) => {
      if (value && value.length < minLength) {
        localError = {
          ...localError,
          [fieldName]: {
            init: true,
            text: `Мінімальна довжина ${minLength} символи`,
          },
        };
        flag = true;
      }
    };

    // Валідація на довжину name
    validateLength(name, 'name', 2);

    // Валідація на довжину міста
    validateLength(city, 'city', 2);

    // Валідація на корректне посилання
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    if (instagramLink && !urlRegex.test(instagramLink)) {
      localError = {
        ...localError,
        instaLink: { init: true, text: 'Некоректне посилання' },
      };
      flag = true;
    }

    setError(localError);

    return flag;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const { name, email, instagramLink, city, file } = data;

    if (!validation()) {
      const formData = new FormData();
      if (file) formData.append('avatar', file);
      formData.append('name', name);
      formData.append('email', email);
      formData.append('instagramLink', instagramLink);
      formData.append('city', city);
      formData.append('consent', consent);

      dispatch(userOperation.changeUserInfo(formData));
    }
  };
  const { name, email, instagramLink, city, consent, file, avatar } = data;
  return (
    <Form onSubmit={handleSubmit}>
      <WrapperAvatar>
        <AvatarUpload
          avatar={avatar}
          file={file}
          name={userInfo?.name ? userInfo.name : ''}
          onChange={onInputChange}
        />
      </WrapperAvatar>

      <WrapperRight>
        {/* Ім'я */}
        <TextField
          error={error?.name?.init}
          helperText={error?.name?.text}
          id="outlined-basic"
          label="Ім'я"
          variant="outlined"
          name="name"
          value={name}
          onInput={onInputChange}
          size="small"
          margin="dense"
          autoComplete="off"
          sx={{ width: '100%', minWidth: '220px' }}
          inputProps={{
            maxLength: 100,
          }}
        />

        {/* Email */}
        <TextField
          error={error?.email?.init}
          helperText={error?.email?.text}
          id="outlined-basic"
          label="Email"
          variant="outlined"
          name="email"
          value={email}
          onInput={onInputChange}
          size="small"
          margin="dense"
          autoComplete="off"
          sx={{ width: '100%', minWidth: '220px' }}
        />

        {/* Посилання на інсту */}
        <TextField
          error={error?.instaLink?.init}
          helperText={error?.instaLink?.text}
          id="outlined-basic"
          label="Instagram"
          variant="outlined"
          name="instagramLink"
          value={instagramLink}
          onInput={onInputChange}
          size="small"
          margin="dense"
          autoComplete="off"
          maxLength={250}
          sx={{ width: '100%', minWidth: '220px' }}
          inputProps={{
            maxLength: 250,
          }}
        />

        {/* Місто */}
        <TextField
          error={error?.city?.init}
          helperText={error?.city?.text}
          id="outlined-basic"
          label="Місто"
          variant="outlined"
          name="city"
          value={city}
          onInput={onInputChange}
          size="small"
          margin="dense"
          autoComplete="off"
          sx={{ width: '100%', minWidth: '220px' }}
          inputProps={{
            maxLength: 100,
          }}
        />

        {/* Згода на отримання інформації */}
        <Checkline>
          <Checkbox
            checked={consent}
            onChange={e => setData({ ...data, consent: e.target.checked })}
            color="primary"
          />{' '}
          <Label>
            Ви даєте згоду на отримання інформації на вашу приватну електрону
            адресу щодо старту нового курсу
          </Label>
        </Checkline>
        <ButtonWrapper>
          {' '}
          <Button
            type="submit"
            variant="outlined"
          //sx={{ width: '100%', minWidth: '220px' }}
          >
            Зберегти
          </Button>
        </ButtonWrapper>
      </WrapperRight>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  align-items: start;
  margin-top: 10px;
  width: 100%;

  button {
    margin-top: 20px;
  }

  @media screen and (min-width: 830px) {
    flex-direction: row;
    justify-content: center;
    width: 70%;
  }

  @media screen and (min-width: 830px) {
    width: initial;
    margin-left: 20px;
  }
`;

const WrapperAvatar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;

  @media screen and (min-width: 830px) {
    width: initial;
    margin-left: 20px;
  }
`;

const Checkline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  line-height: 10px;
  width: 100%;
`;
const WrapperRight = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;

  @media screen and (min-width: 830px) {
    margin-left: 50px;
  }
`;

const Label = styled.label`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;
`;
