import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import authSelectors from '@redux/auth/auth-selectors';
import PropTypes from 'prop-types';

export function PrivateRoute({ element }) {
  const token = useSelector(authSelectors.getUserToken);
  return <>{token ? element : <Navigate to={'/login'} />}</>;
}

PrivateRoute.propTypes = {
  element: PropTypes.node,
};
